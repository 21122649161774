//Urspruenglich
//import { Controller } from "stimulus"
//export default class extends Controller {
import StimulusReflex from 'stimulus_reflex'
import ApplicationController from './application_controller'
export default class extends ApplicationController {
  
  static targets = [ "form", "modal" ]

  connect () {
    StimulusReflex.register(this)
  }

  go(e){
    e.preventDefault()
    this.close()
    
    console.log('test')
  }
  beforeReflex (element, reflex) {
    //document.body.classList.add('bg-gray-800')
    console.log("trefferm message")
    //document.getElementById("overlay").style.display = "block"
  }
  afterReflex (element, reflex) {
    //document.body.classList.remove('bg-gray-800')
  }
}