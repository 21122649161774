// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
window.Rails = require("@rails/ujs")
//require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("stylesheets/application.scss")
require("turbolinks").start()


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"
import "src/date_time_picker"
import Rails from "@rails/ujs"

// ADD YOUR JAVACSRIPT HERE

// Start Rails UJS
Rails.start()